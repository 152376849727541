<template>
  <nav class="c-pagination" :aria-label="label">
    <ul class="c-pagination__items">
      <li v-for="item in items" :key="item.value" class="c-pagination__item">
        <app-link
          v-if="item.type === 'link'"
          :class="[
            { 'c-pagination__link--active': activePage === item.value },
            'c-pagination__link',
          ]"
          :disabled="activePage === item.value"
          :aria-disabled="activePage === item.value"
          :aria-label="$t('{prefix}ページへ', { prefix: item.value })"
          @click="setPage(item.value)"
          v-text="item.value"
        />
        <span v-else class="c-pagination__link" role="separator">...</span>
      </li>
    </ul>
    <ul class="c-pagination__controls">
      <li class="c-pagination__control">
        <app-link
          class="c-pagination__controlLink"
          :aria-label="$t('{prefix}ページへ', { prefix: $t('前の') })"
          :disabled="activePage === 1"
          :aria-disabled="activePage === 1"
          @click="setPage(activePage - 1)"
          v-t="'« 前へ'"
        />
      </li>
      <li class="c-pagination__control">
        <app-link
          class="c-pagination__controlLink"
          :aria-label="$t('{prefix}ページへ', { prefix: $t('次の') })"
          :disabled="activePage === pages"
          :aria-disabled="activePage === pages"
          @click="setPage(activePage + 1)"
          v-t="'次へ »'"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'CPagination',
  props: {
    activePage: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 10,
    },
    limit: {
      type: Number,
      default: 3,
    },
    separator: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default() {
        return this.$t('ページネーション')
      },
    },
  },
  computed: {
    computedLimit() {
      return Math.max(1, this.limit - 1)
    },
    maxPrevItems() {
      return Math.floor(this.computedLimit / 2)
    },
    maxNextItems() {
      return Math.ceil(this.computedLimit / 2)
    },
    firstItem() {
      return Math.max(1, this.activePage - this.maxPrevItems)
    },
    lastItem() {
      return Math.min(this.pages, this.activePage + this.maxNextItems)
    },
    showSeparator() {
      return this.separator && this.limit > 0
    },
    showBeforeSeparator() {
      return this.showSeparator && this.firstItem > 2
    },
    showAfterSeparator() {
      return this.showSeparator && this.lastItem < this.pages - 1
    },
    items() {
      const result = []

      if (this.firstItem > 1) {
        // 最初のページ
        result.push({
          type: 'link',
          value: 1,
        })
      }

      if (this.showBeforeSeparator) {
        // 最初のページ
        result.push({
          type: 'separator',
          value: 'beforeSeparator',
        })
      }

      for (let i = this.firstItem; i <= this.lastItem; i++) {
        result.push({
          type: 'link',
          value: i,
        })
      }

      if (this.showAfterSeparator) {
        // 最初のページ
        result.push({
          type: 'separator',
          value: 'afterSeparator',
        })
      }

      if (this.lastItem < this.pages) {
        // 最初のページ
        result.push({
          type: 'link',
          value: this.pages,
        })
      }

      return result
    },
  },
  methods: {
    setPage(number) {
      if (number === this.activePage) return
      this.$emit('update:activePage', number, false)
    },
  },
}
</script>
