<template>
  <dl class="p-faqAnswerItem">
    <dt class="p-faqAnswerItem__title">
      <slot name="title" />
    </dt>
    <dd class="p-faqAnswerItem__content">
      <slot name="content" />
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'PFaqAnswerItem',
}
</script>
