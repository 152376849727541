<template>
  <article class="c-article">
    <header class="c-article__header">
      <div class="l-container">
        <h1 class="c-article__title">{{ $t('よくあるご質問') }}</h1>
      </div>
    </header>
    <div class="c-article__content">
      <div class="l-container p-faqDetail">
        <h2 class="p-faqDetail__title" v-text="title"></h2>
        <div class="p-faqDetail__list" ref="qaArea">
          <PFaqAnswerItem
            v-for="(item, index) in qaContent"
            :key="index"
            class="p-faqDetail__item"
          >
            <template #title>{{ item.question }}</template>
            <template #content>{{ item.answer }}</template>
          </PFaqAnswerItem>
        </div>
      </div>
      <CPagination
        class="p-faqDetail__pagination"
        :active-page="activePage"
        :pages="pages"
        :limit="5"
        :label="$t('よくあるご質問一覧のページネーション')"
        @update:activePage="handleUpdateActivePage"
      />
    </div>
  </article>
</template>

<script>
import CPagination from '@/components/common/CPagination.vue'
import PFaqAnswerItem from '@/components/faq/PFaqAnswerItem.vue'

export default {
  name: 'FaqDetailPresentation',
  inject: ['scrollTo'],
  components: {
    CPagination,
    PFaqAnswerItem,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  metaInfo: {
    htmlAttrs: {
      class: '-background-white',
    },
  },
  computed: {
    title() {
      return `${this.data.category} - ${this.data.subCategory}`
    },
    activePage() {
      const queryPage = Number(this.$route.query.page) || 1
      return Math.min(queryPage, this.pages)
    },
    pages() {
      return Math.ceil(this.data.items.length / 10)
    },
    qaArea() {
      return this.$refs.qaArea
    },
    qaStart() {
      return (this.activePage - 1) * 10
    },
    qaEnd() {
      return Math.min(this.activePage * 10, this.data.items.length)
    },
    qaContent() {
      return this.data.items.slice(this.qaStart, this.qaEnd)
    },
  },
  methods: {
    handleUpdateActivePage(page) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page,
        },
      })
      this.scrollQaAreaTop()
    },
    scrollQaAreaTop() {
      this.scrollTo(this.qaArea)
    },
  },
}
</script>
