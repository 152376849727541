<template>
  <FaqDetailPresentation :data="data" />
</template>

<script>
import { defaultLayoutViewMixin } from '@/mixins'
import FaqDetailPresentation from '@/components/faq/FaqDetailPresentation.vue'
import { getFaqCsv } from '@/assets/request/csv/getFaqCsv'

export default {
  name: 'FaqDetail',
  mixins: [defaultLayoutViewMixin],
  components: {
    FaqDetailPresentation,
  },
  metaInfo() {
    return {
      title: `${this.$t('よくあるご質問')} - ${this.data.category}`,
    }
  },
  async beforeRouteEnter(to, _from, next) {
    const result = await getFaqCsv()

    if (result.isError) {
      next({ name: 'Error' })
      return
    }

    const hasData = result.data.list.some(({ subCategory }) =>
      subCategory.some(({ code }) => code === to.params.subCategory)
    )

    if (!hasData) {
      next({ name: 'Error' })
      return
    }

    next((mv) => {
      mv.setList(result.data.list)
    })
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.subCategory === from.params.subCategory) {
      next()
      return
    }

    const result = await getFaqCsv()

    if (result.isError) {
      next({ name: 'Error' })
      return
    }

    const hasData = result.data.list.some(({ subCategory }) =>
      subCategory.some(({ code }) => code === to.params.subCategory)
    )

    if (!hasData) {
      next({ name: 'Error' })
      return
    }

    this.setList(result.data.list)

    next()
  },
  data() {
    return {
      pageParents: [
        {
          label: 'よくあるご質問',
          to: { name: 'Faq' },
        },
      ],
      list: [],
    }
  },
  computed: {
    languageKeys() {
      switch (this.$i18n.locale) {
        case 'en':
          return {
            title: 'titleEnglish',
            question: 'questionEnglish',
            answer: 'answerEnglish',
          }
        default:
          return {
            title: 'title',
            question: 'question',
            answer: 'answer',
          }
      }
    },
    category() {
      return (
        this.list.find(({ subCategory }) =>
          subCategory.some(
            ({ code }) => code === this.$route.params.subCategory
          )
        ) || { subCategory: [] }
      )
    },
    subCategory() {
      return (
        this.category.subCategory.find(
          ({ code }) => code === this.$route.params.subCategory
        ) || { items: [] }
      )
    },
    data() {
      return {
        category: this.category[this.languageKeys.title],
        subCategory: this.subCategory[this.languageKeys.title],
        items: this.subCategory.items.map((item) => ({
          question: item[this.languageKeys.question],
          answer: item[this.languageKeys.answer],
        })),
      }
    },
  },
  methods: {
    setList(list) {
      this.list = list
    },
  },
}
</script>
