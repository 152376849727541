import { requestCsv } from '@/assets/modules/request'
import { FaqViewModel } from '@/assets/request/viewModel/FaqViewModel'

/**
 * よくあるご質問のCSVを取得する
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/faqList').FaqList>>}
 */
export async function getFaqCsv() {
  const response = await requestCsv.get(`/faq/faq.csv`)
  return response.toViewModel(FaqViewModel)
}
