import { parse } from 'csv-parse/lib/sync'

const FaqColumnName = [
  'categoryCode',
  'subCategoryCode',
  'category',
  'categoryEnglish',
  'subCategory',
  'subCategoryEnglish',
  'question',
  'questionEnglish',
  'answer',
  'answerEnglish',
]

export class FaqViewModel {
  constructor(text) {
    /** @type {string} */
    this._raw = text
  }

  /** @type {import('../types/faqList').FaqList} */
  get list() {
    const records = parse(this._raw, {
      trim: true,
      skip_empty_lines: true,
      columns: (header) => header.map((_, index) => FaqColumnName[index]),
    })

    return records.reduce(
      (
        result,
        {
          categoryCode,
          subCategoryCode,
          category,
          categoryEnglish,
          subCategory,
          subCategoryEnglish,
          ...data
        }
      ) => {
        let categoryObj = result.find(({ code }) => code === categoryCode)

        if (!categoryObj) {
          result.push(
            (categoryObj = {
              code: categoryCode,
              title: category,
              titleEnglish: categoryEnglish,
              subCategory: [],
            })
          )
        }

        let subCategoryObj = categoryObj.subCategory.find(
          ({ code }) => code === subCategoryCode
        )

        if (!subCategoryObj) {
          categoryObj.subCategory.push(
            (subCategoryObj = {
              code: subCategoryCode,
              title: subCategory,
              titleEnglish: subCategoryEnglish,
              items: [],
            })
          )
        }

        subCategoryObj.items.push(data)
        return result
      },
      []
    )
  }
}
